button {
    font-size: 100%; /* Corrects font size not being inherited in all browsers */
    margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
    vertical-align: baseline; /* Improves appearance and consistency in all browsers */
}

.button, button, input[type="submit"], input[type="button"], input[type="reset"] {
    display: inline-block;
    position: relative;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    font-weight: normal;
    border: 0 none;
    margin: 0 2px;
	font-size: $font-size;
	@include fade;
    font-weight: $font-weight-body;
    font-family: $font-family;
    text-transform: capitalize;
}
