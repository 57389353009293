
@import "variables";
@import "mixin";
@import "button";


html, body {
  width: 100%;
  height: 100%;
}


body{
  background-color:$color_background;
  color: $white;
  margin: 0;
  padding: 0;
  letter-spacing:0.05em;
  text-align:center;
  font-family: 'Athiti', sans-serif;
  overflow-y: hidden;
  line-height: 1.325;
  font-size: $font-size;
}

*{
  box-sizing: border-box;
}

img {
  max-width: 100%;
}
.bg_left, .bg_right {
  position: fixed;
  width: 100%;
  
  z-index: -1;

  @include desktop {
    height: 50%;
    top: 0;
    height: 100%;
    width: 50%;
  }
}

.bg_left {
  top: 0;
  background-color: $color_primary;
  height: 70%;
  @include tablet {
    height: 50%;
  }
  @include desktop {
    height: 100%;
    left: 0;
  }
}
.bg_right {
  bottom: 0;
  background-color: #fff;
  height: 30%;
  @include tablet {
    height: 50%;
  }
  @include desktop {
    height: 100%;
    right: 0;
  }
}

.language {
  right: 70px;
  position: absolute;
  top: 30px;
  color: $white;
  border: 1px solid $white;
  padding: 0.3rem 0.5rem;
  transition: all 0.5s;
  
  .anticon-down {
    font-size: 1.8rem;
    position: relative;
    top: 10px;
  }
  
  ul, li{
    margin:0;
    padding:0;
    list-style-type: none;
    
    a {
      text-decoration: none;
      display: block;
      transition: all 0.5s;
      padding: 0.6rem 0rem;
      color: $white;
      @include desktop {
        color: $color_secondary;
      }
      &:hover {
        color: $color_primary;
      }
    }
  }
  ul {
    display: none;
  }
  &:hover ul{
    transition: all 0.5s;
    display: block;
  }


  @include desktop {
    top: 10px;
    color: $color_primary;
    border: 1px solid $color_primary;
  }

}

.content-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: wrap column;
  height: 100%;
  min-height: 570px;
  max-width: 70%;
  margin: 0 auto;

  @include desktop {
    min-height: 570px;
    max-width: 1000px;
    flex-flow: nowrap row;
  }
}

.content-left {
  position: relative;
  width: 100%;
  padding: 0;
  text-align: left;

  strong {
    display: block;
    margin-bottom: 1rem;
  }

  @include desktop {
    padding: 50px;
    max-width: 700px;
  }

}

.content-right {
    text-align: center;
    padding-top: 4rem;
    @include desktop {
      margin-top: 50px;
      width: 100%;
      padding: 50px;
    }
    .slogan {
      color:#333; 
      text-align:right; 
      display:block; 
      margin-top:1rem;
    }
}

.social {
  display: flex;
  justify-content: start;

  a {
    display: inline-block;
    text-decoration: none;
    background-color: lighten($color_primary, 5%);
    font-weight: bold;
    padding: 0.2rem 0.7rem;
    color: #fff;
    transition: all 0.5s;

    &:hover{
      background-color: lighten($color_primary, 15%);
    }
    &:last-child {
      margin-left: 1rem;
    }
  }
  img {
    max-width: 30px;
    opacity: 0.5;
  }
}

.copyright {
  // background-color: black;
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  position: absolute;
  bottom: 0;
  left: 10%;
  color: #333;

  @include tablet {
    left: 25%;
  }

  @include desktop {
    font-size: 0.8rem;
    left: 40%;
  }

}