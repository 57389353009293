// ***********************************
// MIXINS
// ***********************************

@mixin transition-all($time:1s){
	-webkit-transition: all $time ease-in-out;
	-moz-transition: all $time ease-in-out;
	-ms-transition: all $time ease-in-out;
	-o-transition: all $time ease-in-out;
	transition: all $time ease-in-out;
}

@mixin fade($time:0.5s){
	-webkit-transition: background-color $time ease-in-out;
	-moz-transition: background-color $time ease-in-out;
	-o-transition: background-color $time ease-in-out;
	-ms-transition: background-color $time ease-in-out;
	transition: background-color $time ease-in-out;
}



@mixin clearfix(){
	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}
	&:after {
		clear: both;
	}
}

// Layput media queries
@mixin desktop {
	@media (min-width: #{$desktop}) {
	  @content;
	}
  }
  
  @mixin tablet {
	  @media (min-width: #{$tablet}) {
		  @content;
	  }
  }