
@import url('https://fonts.googleapis.com/css?family=Athiti|Squada+One');
// font-family: 'Squada One', cursive;
// font-family: 'Athiti', sans-serif;

$font-size: 18px;
$font-weight-body: 100;

$font-family: 'Athiti', sans-serif;
$font-family-title: 'Squada One', cursive;

$color_primary:#f35369;
$color_secondary:#2d4b6b;
$color_background:#e9e5e5;

//LAYOUT
$desktop: 1050px;
$tablet: 768px;

$white: #ffffff;